import {VideoFrameStatus} from "./video_frame";
export enum ConstentsItemType {
	Video = 0,
	Frame = 1
};

export class ContentsItem {
	id: number;
	title: string;
	url: string;
	thumbnail_url: string;
	thumbnail_hover_url: string;	
	type: ConstentsItemType;
	checked?: boolean = false;
	hovered?: boolean = false;
	status?: VideoFrameStatus;
	group_key: string | number;
};