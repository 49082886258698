import { Injectable } from '@angular/core';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { of } from 'rxjs';
import { filter, switchMap, catchError } from 'rxjs/operators';
import { BasketService } from 'src/app/services/basket.service';
import { Load, ECollectionActions, LoadSuccess, Create, CreateSuccess, ECollectionItemType, Update, UpdateSuccess, Delete, DeleteSuccess } from '../actions/collection.actions';
import { DeSelectFrameBasket, SelectFrameBasket } from '../actions/basket-component.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../state/app.state';
import * as _ from 'lodash';
import { NotificationType } from 'src/app/models/notification';
import { ShowNotification } from '../actions/notifications.actions';
import { Basket } from 'src/app/models/basket';

@Injectable()
export class BasketsEffects {
	constructor(
		private basketService: BasketService,
		private actions$: Actions,
		private store: Store<AppState>
	) {}

	@Effect()
	getBaskets$ = this.actions$.pipe(
		ofType<Load<Basket>>(ECollectionActions.Load),
		switchMap(() => this.basketService.getBaskets()),
		switchMap((baskets) => {
			return [
				new LoadSuccess(baskets, ECollectionItemType.Basket),
				new SelectFrameBasket(baskets.length > 0 ? baskets[0].id : null)
			];
		})
	)

	@Effect()
	createBasket$ = this.actions$.pipe(
		ofType<Create<Basket>>(ECollectionActions.Create),
		switchMap((action) => this.basketService.createBasket(action.item)),
		switchMap((basket) => {
			if (basket) {
				return [
					new CreateSuccess(basket, ECollectionItemType.Basket),
					new ShowNotification("Basket created successfully", NotificationType.Success)
				];
			}
			else {
				return [];
			}
		})
	)

	@Effect()
	updateBasket$ = this.actions$.pipe(
		ofType<Update<Basket>>(ECollectionActions.Update),
		filter(action => action.itemType === ECollectionItemType.Basket),
		switchMap((action) => this.basketService.updateBasket(action.item)),
		switchMap((basket) => {
			if (basket) {
				return [
					new UpdateSuccess(basket, ECollectionItemType.Basket),
					new ShowNotification("Basket updated successfully", NotificationType.Success)
				];
			}
			else {
				return [
					new ShowNotification("Basket update failed", NotificationType.Error)
				];
			}
		}),
		catchError((e) => {
			console.log("Basket update failed", e);
			return [
				new ShowNotification("Basket update failed", NotificationType.Error)
			];
		})
	)

	@Effect()
	deleteBasket$ = this.actions$.pipe(
		ofType<Delete<Basket>>(ECollectionActions.Delete),
		filter(action => action.itemType === ECollectionItemType.Basket),
		switchMap(async (action) => {
			try {
				await this.basketService.deleteBasket(action.item);
			}
			catch(e) {
				console.log("Basket delete failed", e);
				return null;
			}
			return action.item.id;
		}),
		switchMap((basketID) => {
			if (basketID) {
				return [
					new DeleteSuccess(basketID, ECollectionItemType.Basket),
					new DeSelectFrameBasket(basketID),
					new ShowNotification("Basket(s) deleted successfully", NotificationType.Success)
				];
			}
			else {
				return [
					new ShowNotification("Basket(s) delete failed", NotificationType.Error)
				];
			}
		})
	)
}