import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';
import { Auth } from 'aws-amplify';

import { HttpAuthClient } from './http_auth';
import { VideoFrame } from '../models/video_frame';
import * as moment from 'moment';
import { HttpServiceBase } from './http_service_base';
import { AppState } from '../store/state/app.state';
import { CollectionMetadata } from '../models/collection_metadata';
import { FilterPair } from '../models/filter_pair';

@Injectable({
  providedIn: 'root'
})
export class VideoFrameService extends HttpServiceBase {
  constructor(
    private httpAuth: HttpAuthClient,
    protected store : Store<AppState>
  ) {
    super(store);
  }

  private fixTimestamps(source: Observable<VideoFrame[]>) : Observable<VideoFrame[]> {
    return source.map<VideoFrame[], VideoFrame[]>(res =>
      res.map(elem => ({
        ...elem, 
        ...{
          // @ts-ignore
          timestamp: parseInt(elem.timestamp)
        }
      })));
  }

  getFrames() : Observable<VideoFrame[]> {
    console.log("getFrames");
    let params = new HttpParams();
    return this.fixTimestamps(
      Observable
      .fromPromise(Auth.currentAuthenticatedUser())
      .switchMap((currentUser) => {
        return this.httpAuth
        .get(`${this.serverUrl}/api/video_frames/`, {params : params})
        .map((res: any) => res.data)
      })
    ); 
  }

  getFramesUsers() : Observable<string[]> {
    // @ts-ignore
    return this.httpAuth.get(`${this.serverUrl}/api/video_frames/users`);
  }

  getFramesEx(video_id: number, user_id?: string, deleted?: boolean, idSort?: number, videoSort?: number) : Observable<VideoFrame[]> {
    // @ts-ignore
    let params = new HttpParams();
    if (video_id >= 0) {
      params = params.append("video", video_id.toString());
    }
    if (user_id != undefined && user_id !== "") {
      params = params.append("user", user_id.toString());
    }
    if (deleted) {
      params = params.append("deleted", deleted.toString());
    }
    if(idSort) {
      params = params.append("idSort", idSort.toString());
    }
    if(videoSort) {
      params = params.append("videoSort", videoSort.toString());
    }

    return this.fixTimestamps(
      this.httpAuth
      .get<VideoFrame[]>(`${this.serverUrl}/api/video_frames/`, {params : params}));
  }


  getFramesByFilters(filters: FilterPair[], sorts: any[], limit: number, offset: number, search: string) : Observable<{data: VideoFrame[], metadata: CollectionMetadata }> {
    // @ts-ignore
    let sort_request: string = "";
    for (let i = 0; i < sorts.length; i++)
    {
      let sort = sorts[i];
      sort_request += ((i == 0) ? '' : ',') + sort.name + ':' + sort.order.toString();
    }    

    let params = new HttpParams();
    if (sort_request !== "") {
      params = params.append("sort", sort_request);
    }

    for (let i = 0; i < filters.length; i++)
    {
      let filter = filters[i];
      params = params.append(filter.name, filter.value);
    }    

    if (limit > 0) {
      params = params.append("limit", limit.toString());
    }    
    if (offset > 0) {
      params = params.append("offset", offset.toString());
    }
    if (search !== null && search !== undefined && search.trim() != '') {
      params = params.append('search', search);
    }

    const result$ = 
      this.httpAuth
      .get<any>(`${this.serverUrl}/api/video_frames/`, {params : params});
    const withFixedTimestamps$ = this.fixTimestamps(
      result$.map(result => result.data )
    );
    return combineLatest(withFixedTimestamps$, result$)
    .map(([data, result]) => ({
      data: data,
      metadata: result.metadata
    }));
  }


  async deleteFrames(frames: VideoFrame[]) : Promise<HttpErrorResponse> {
    console.log("deleteFrames", frames);

    try {
      await this.httpAuth.post(
        `${this.serverUrl}/api/video_frames/delete/`,
        {
          frames: frames.map(f => f.id)
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }).toPromise();
    }
    catch(e) {
      return e;
    }

    return null;
  }

  async createFrame(frame: VideoFrame) {
    console.log("video_frame.service: createFrame", frame);

    const currentTime = moment();
    const frameName = currentTime.valueOf().toString() + '.png';

    const currentUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();

    frame.id = null;
    frame.deleted = false;
    frame.timestamp = currentTime.valueOf();
    frame.user_id = currentUser.username;

    /**
     *   We first request target url from backend. This would return us the AWS s3 url to which we would post
     **/
    let urlData = null;
    try {
      urlData = await this.httpAuth.get<{url?: string, error? : any}>(
        `${this.serverUrl}/api/getSingleFileUploadUrl/?fileName=${frameName}`, {})
        .toPromise();
    }
    catch(e) {
      console.log('video_frame.service: createFrame: Error during frame signed url retrieval: ', e);
      throw e;
    }

    if (urlData.error) {
      throw urlData.error.message;
    }

    const url : string = urlData.url;
    console.log(`video_frame.service: createFrame: frame upload url: ${url}`);

    const data = this.dataURItoBlob(frame.url);

    let request: any = {
      method: 'PUT',
      cache: 'no-cache',
      body: data,
      headers: {
        'Content-Length': String(data.size),
        'accesstoken' : currentSession.getAccessToken().getJwtToken()
      }
    }
    if (currentUser.dropTo) {
      request.headers['x-hawk-dropto'] = currentUser.dropTo;
    }
    let uploadResult = null;
    try {
      uploadResult = await fetch(url, request);
    }
    catch(e) {
      console.log('video_frame.service: createFrame: Error. Failed to upload frame to s3.', e);
      throw e;
    }
    console.log('video_frame.service: createFrame: aws upload result:', uploadResult);

    if (!uploadResult || !uploadResult.url) {
      console.log('video_frame.service: createFrame: Error. No frame aws upload result', uploadResult);
      return;
    }
    frame.url = uploadResult.url.substring(0, uploadResult.url.indexOf("?"));
    frame.thumbnail_url = null;

    let updatedFrame = null;
    try {
      updatedFrame = await this.httpAuth.post(
        `${this.serverUrl}/api/video_frames/create/`,
        frame,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }).toPromise();
    }
    catch(e) {
      console.log('video_frame.service: createFrame: Error during new frame saving.', e);
      throw e;
    }

    return updatedFrame;
  }

  private dataURItoBlob(dataURI : string) : Blob {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);
  
    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  
    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
  
    // create a view into the buffer
    var ia = new Uint8Array(ab);
  
    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
  
    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], {type: mimeString});
    return blob;
  
  }

  updateFrame(frame: VideoFrame) : Observable<VideoFrame> {
    return Observable
    .fromPromise(Auth.currentAuthenticatedUser())
    .switchMap((currentUser) => {
      return this.httpAuth.post<VideoFrame>(`${this.serverUrl}/api/video_frames/update`, frame, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
    });
  }
}
