import { Action } from '@ngrx/store';
import { Basket } from 'src/app/models/basket';
import { BasketComponentState } from 'src/app/models/basket_component_state';
export enum EBasketComponentActions {
  SelectFrameBasket = '[BasketComponent] SelectFrameBasket',
  DeSelectFrameBasket = '[BasketComponent] DeselectFrameBasket',
  ChangeState = '[BasketComponent] ChangeState',
  SetBasketModified = '[BasketComponent] SetBasketModified',
  ResetModifiedBaskets = '[BasketComponent] ResetModifiedBaskets'
}

export class SelectFrameBasket implements Action {
  public readonly type = EBasketComponentActions.SelectFrameBasket;
  constructor (public basketId: number) {}
}

export class DeSelectFrameBasket implements Action {
  public readonly type = EBasketComponentActions.DeSelectFrameBasket;
  constructor (public basketId: number) {}
}

export class ChangeState implements Action {
  public readonly type = EBasketComponentActions.ChangeState;
  constructor (public state: BasketComponentState) {}
}

export class SetBasketModified implements Action {
  public readonly type = EBasketComponentActions.SetBasketModified;
  constructor (public basket: Basket) {}
}

export class ResetModifiedBaskets implements Action {
  public readonly type = EBasketComponentActions.ResetModifiedBaskets;
  constructor () {}
}

export type BasketComponentActions = SelectFrameBasket | DeSelectFrameBasket | ChangeState | SetBasketModified | ResetModifiedBaskets;