import { Component, ViewChild, ElementRef, AfterViewInit, Input, OnChanges, OnDestroy, Output, EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import UIkit from 'uikit';
import * as moment from 'moment';

import { ContentsItem, ConstentsItemType } from 'src/app/models/contents_item';
import { ContentsItemGroup } from 'src/app/models/contents_item_group';
import { input } from 'aws-amplify';
import { SortOrder } from 'src/app/models/sort_order';

@Component({
  selector: 'contents-item-list',
  templateUrl: './contents-item-list.component.html',
  styleUrls: ['./contents-item-list.component.scss']
})

export class ContentsItemListComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() items: ContentsItem[];
  @Input() previewSize: string;
  @Input() paging: boolean = false;
  @Input() pageNum: number = 0;
  @Input() totalPages: number = 0;
  @Input() sortOrder: SortOrder = SortOrder.Desc;
  @Output("onCheckedItemsChanged") onCheckedItemsChanged = new EventEmitter<{items: ContentsItem[], groups: ContentsItemGroup[]}>();
  @Output("onPageChanged") onPageChanged = new EventEmitter<number>();

  @ViewChild('canvas', { static: false }) canvas: ElementRef;
  @ViewChild('viewItemModal', {static: false }) viewItemModal: ElementRef;

  ctx: any;
  haveTitles: boolean = true;
  itemToView = null;
  groupedItems: ContentsItemGroup[];
  lastUploadedFileUrl: string;
  showGroups: boolean = true;

  ngOnDestroy() {
    const modal = UIkit.modal(this.viewItemModal.nativeElement);
    if (modal) {
      modal.hide();
    }
  }

  ngOnChanges() {
    this.haveTitles = this.items.findIndex(item => !!item.title) >= 0;

    this.groupedItems = _
      .chain(this.items)
      .orderBy(item => item.group_key, this.sortOrder === SortOrder.Asc ? 'asc' : 'desc')
      .groupBy(item => item.group_key)
      .map((group, key) => ({
        key: key,
        items: group,
        checked: false
      }))
      .orderBy(group => group.key.valueOf(), this.sortOrder === SortOrder.Asc ? 'asc' : 'desc')
      .value();

    this.showGroups = this.groupedItems.findIndex(g => g.items.length > 1) >= 0;
  }

  ngAfterViewInit(): void {
    UIkit.util.on(this.viewItemModal.nativeElement, 'hidden', () => {
      this.itemToView = null;
    });
  }

  onItemClick(file: ContentsItem) {
    this.itemToView = file;
    UIkit.modal(this.viewItemModal.nativeElement).show();
  }
  onItemViewClick() {
    UIkit.modal(this.viewItemModal.nativeElement).hide();
    this.itemToView = null;
  }
  onNextItemToViewClick() {
    const currentItemIdx = this.items.indexOf(this.itemToView)
    if (currentItemIdx + 1 < this.items.length) {
      this.itemToView = this.items[currentItemIdx + 1];
    }
  }
  onPrevItemToViewClick() {
    const currentItemIdx = this.items.indexOf(this.itemToView)
    if (currentItemIdx > 0) {
      this.itemToView = this.items[currentItemIdx - 1];
    }
  }

  /** Experimental  **/
  thumbnailChange(event: any, state:number, content_info:any ) : void
  {
    /*console.log(state.toString + "  " + event);*/
    if (state == 1) {
      event.target.src = (content_info.thumbnail_hover_url != null) ? content_info.thumbnail_hover_url : content_info.thumbnail_url     ;
    } 
    if (state == 0) {
      event.target.src = (content_info.thumbnail_url       != null) ? content_info.thumbnail_url       : content_info.thumbnail_hover_url;
    } 
    
  }

  isGroupChecked(group: ContentsItemGroup) : boolean {
    return group.items.findIndex(item => !item.checked) < 0;
  }

  checkGroup(group: ContentsItemGroup, event: MouseEvent): void {
    // @ts-ignore
    const isChecked = event.target.checked;
    group.items.forEach(item => { item.checked = isChecked; });

    this.emitOnCheckedItemsChanged();
  }

  isEntireGroupChecked(group: ContentsItemGroup) : boolean {
    return group.checked;
  }

  checkEntireGroup(group: ContentsItemGroup, event: MouseEvent): void {
    // @ts-ignore
    const isChecked = event.target.checked;
    group.items.forEach(item => { item.checked = isChecked; });
    group.checked = isChecked;


    this.emitOnCheckedItemsChanged();
  }


  checkItem(item: ContentsItem, event: MouseEvent) {
    // @ts-ignore
    const isChecked = event.target.checked;
    item.checked = isChecked;

    this.emitOnCheckedItemsChanged();
  }

  emitOnCheckedItemsChanged() {
    const checkedItems = this.items.filter(item => item.checked);
    const checkedGroups = this.groupedItems.filter(gr => gr.checked);
    this.onCheckedItemsChanged.emit({
      items: checkedItems,
      groups: checkedGroups
    });
  }

  isVideo(item: ContentsItem) : boolean
  {
      return item.type == ConstentsItemType.Video;
  }

  onItemEnter(item: ContentsItem) : void {
    for (const oneItem of this.items) {
      if (oneItem !== item && oneItem.hovered) {
        oneItem.hovered = false;
      }
    }
    item.hovered = true;
  }

  onItemLeave(item: ContentsItem) : void {
    item.hovered = false;
  }

  firstPage() {
    this.onPageChanged.emit(0);
  }

  lastPage() {
    this.onPageChanged.emit(this.totalPages - 1);
  }

  prevPage(numberOfPages: number) {
    const targetPage = this.pageNum - numberOfPages;
    if (targetPage >= 0) {
      this.onPageChanged.emit(targetPage);
    } else {
      this.onPageChanged.emit(0);
    }
  }

  nextPage(numberOfPages: number) {
    const targetPage = this.pageNum + numberOfPages;
    if (targetPage < this.totalPages) {
      this.onPageChanged.emit(targetPage);
    } else {
      this.onPageChanged.emit(this.totalPages - 1);
    }
    
  }

}
