import { Basket } from "src/app/models/basket";
import { BasketComponentState as State } from "src/app/models/basket_component_state";

export interface BasketComponentState {
  frameBasketIds: number[],                // Active frame-basket id
  state: State,                            // State of basket screen
  modifiedBaskets: Record<number, Basket>  // Baskets that have been edited
}

export const initialBasketComponentState : BasketComponentState = {
  frameBasketIds: [],
  state: State.Initial,
  modifiedBaskets: {}
};