import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { VideoFrame } from 'src/app/models/video_frame';
import { Basket, BasketType } from 'src/app/models/basket';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/store/state/app.state';
import { Create, ECollectionItemType, Update } from 'src/app/store/actions/collection.actions';
import { ShowNotification } from 'src/app/store/actions/notifications.actions';
import { NotificationType } from 'src/app/models/notification';
import { Observable, Subscription } from 'rxjs';
import { selectFrameBasketsWithItems } from 'src/app/store/selectors/basket-component.selectors';
import * as _ from 'lodash';
import { SetBasketModified } from 'src/app/store/actions/basket-component.actions';

@Component({
  selector: 'frames-actions',
  templateUrl: './frames-actions.component.html',
  styleUrls: ['./frames-actions.component.scss']
})
export class FramesActionsComponent implements OnInit, OnDestroy {
  @Input() checkedFrames: VideoFrame[];
  @Output() onAnnotate = new EventEmitter();
  @ViewChild('createBasketModal', {static: false }) createBasketModal: ElementRef;

  newBasketName: string = null;
  baskets: Basket[];
  selectedBasketAddTo: Basket = null;

  private baskets$ : Observable<Record<number, Basket>> = this.store.pipe(select(selectFrameBasketsWithItems));
  private subscriptions: Subscription[] = [];

  constructor(
    private store: Store<AppState>
  ) { }

  ngOnInit() {
    this.subscriptions.push(
      this.baskets$.subscribe(baskets => {
        this.baskets = _.values(baskets);
        console.log("baskets.component: frames-baskets ", Object.keys(baskets).length, baskets);
      })
    );
  }

  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  annotateFrame() {
    this.onAnnotate.emit();
  }

  async createBasket() {
    this.store.dispatch(new ShowNotification("Creating basket...", NotificationType.Information));
    const newBasket = <Basket>{
      name: this.newBasketName,
      type: BasketType.VideoFrames,
      videoFrameIds: this.checkedFrames.map(f => f.id)
    };
    this.store.dispatch(new Create(newBasket, ECollectionItemType.Basket));
  }

  areFramesInBasket(basket: Basket) {
    return this.checkedFrames.findIndex(frame =>
      basket.videoFrames.findIndex(frame2 => frame2 && frame2.id === frame.id) < 0) < 0;
  }

  addToBasket() {
    const basket = this.selectedBasketAddTo;
    if(!basket){
      this.store.dispatch(new ShowNotification("Select a basket", NotificationType.Information));
      return
    }
    this.store.dispatch(new ShowNotification("Updating basket...", NotificationType.Information));
    let newVideoFrameIds = [...basket.videoFrameIds];
    let newVideoFrames = [...basket.videoFrames];
    for(let frame of this.checkedFrames) {
      if(basket.videoFrameIds.findIndex(frameId => frameId === frame.id) < 0) {
        newVideoFrameIds.push(frame.id);
        newVideoFrames.push(frame);
      }
    }
    this.store.dispatch(new Update({
      ...basket,
      videoFrameIds: newVideoFrameIds,
      videoFrames: newVideoFrames
    }, ECollectionItemType.Basket));
    console.log("addToBasket" , newVideoFrameIds, basket.id);
  }

}
