import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import ObjectOntologyClass from 'src/app/models/object_ontology_class';
import { Basket } from 'src/app/models/basket';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/store/state/app.state';
import { DeSelectFrameBasket, SelectFrameBasket, SetBasketModified } from 'src/app/store/actions/basket-component.actions';
import { selectBasketComponentState, selectFrameBasketIds, selectFrameBasketsWithItems } from 'src/app/store/selectors/basket-component.selectors';
import * as _ from 'lodash';
import { OntologySelectors } from 'src/app/store/selectors/collection.selectors';
import { ContentsItem } from 'src/app/models/contents_item';
import { BasketComponentState } from 'src/app/models/basket_component_state';

@Component({
  selector: 'basket',
  templateUrl: './basket.component.html',
  styleUrls: ['./basket.component.scss']
})
export class BasketComponent implements OnInit, OnDestroy {
  baskets: Basket[];
  items : Record<number, ContentsItem[]> = {};
  allClasses: Record<number, ObjectOntologyClass>;
  selectedFrameBasketIds: number[];
  state: BasketComponentState = BasketComponentState.Initial;

  private baskets$ : Observable<Record<number, Basket>> = this.store.pipe(select(selectFrameBasketsWithItems));
  private allClasses$: Observable<Record<number, ObjectOntologyClass>> = this.store.pipe(select(OntologySelectors.selectDict));
  private selectedFrameBasketIds$ : Observable<number[]> = this.store.pipe(select(selectFrameBasketIds));
  private state$ : Observable<BasketComponentState> = this.store.pipe(select(selectBasketComponentState));
  private subscriptions: Subscription[] = []; 
  
  constructor(
    private store: Store<AppState>) { }

  ngOnInit() {
    this.subscriptions.push(
      this.baskets$.subscribe(baskets => {
        this.baskets = _.values(baskets);
        console.log("baskets.component: frames-baskets ", Object.keys(baskets).length, baskets);
      })
    );
    this.subscriptions.push(
      this.allClasses$.subscribe(classes => {
        this.allClasses = classes;
        console.log("baskets.component: classes ", Object.keys(classes).length);
      })
    );
    this.subscriptions.push(
      this.selectedFrameBasketIds$.subscribe(selectedBasketId => {
        this.selectedFrameBasketIds = selectedBasketId;
        console.log("baskets.component: selectedFrameBasketId ", selectedBasketId);
      })
    );
    this.subscriptions.push(
      this.state$.subscribe(state => {
        this.state = state;
      })
    );
  }

  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  onSelectedFrameBasketChanged(basket: Basket) {
    if(this.selectedFrameBasketIds.includes(basket.id)) {
      this.store.dispatch(new DeSelectFrameBasket(basket.id));
    } else {
      this.store.dispatch(new SelectFrameBasket(basket.id));
    }
  }

  getClassPathString(oclass: ObjectOntologyClass): string {
    const path = oclass.path;
    if (!path || path.length <= 2) {
      return "";
    }
    else {
      return path.slice(0, path.length - 2).join('/');
    }
  }

  getClassParentName(oclass: ObjectOntologyClass): string {
    if (oclass.parent_id < 0) {
      return "";
    }
    else {
      const parent = this.allClasses[oclass.parent_id];
      return parent.name;
    }
  }

  onFrameRemove(basket: Basket, contentsItem: ContentsItem) {
    this.store.dispatch(new SetBasketModified({
      ...basket,
      videoFrameIds: basket.videoFrameIds.filter(frameID => frameID != contentsItem.id),
      videoFrames: basket.videoFrames.filter(frame => frame && frame.id != contentsItem.id)
    }));
    console.log("onBasketModified" , basket.videoFrameIds, contentsItem.id);
  }

  onTitleEdit(basket: Basket, value: string) {
    this.store.dispatch(new SetBasketModified({
      ...basket,
      name: value
    }));
  }

}
