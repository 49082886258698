import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { HttpAuthClient } from './http_auth';
import { Observable } from 'rxjs/Rx';
import { of } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/combineLatest';
import { Store } from '@ngrx/store';
import { Auth } from 'aws-amplify';

import { Basket, BasketType }               from '../models/basket';
import { VideoFrame }           from '../models/video_frame';
import UploadedFile         from '../models/uploaded_file';

import ObjectOntologyClass  from '../models/object_ontology_class';
import { AppState } from '../store/state/app.state';
import { HttpServiceBase } from './http_service_base';

@Injectable({
  providedIn: 'root'
})
export class BasketService extends HttpServiceBase {
  constructor(
    private httpAuth: HttpAuthClient,
    protected store : Store<AppState>
  ) {
    super(store);
  }

  getBaskets() : Observable<Basket[]> {
    return Observable
      .fromPromise(Auth.currentAuthenticatedUser())
      .switchMap((currentUser) => {
    return this.httpAuth
      .get<any[]>(`${this.serverUrl}/api/baskets/`, {})
      .map(data => data.map(basket => {
        let basketType = BasketType.Videos;
        if (basket.frames && basket.frames.length > 0) {
          basketType = BasketType.VideoFrames;
        }
        else if (basket.classes && basket.classes.length > 0) {
          basketType = BasketType.Classes;
        }
        return {
          id: basket.id,
          name: basket.name,
          type: basketType,
          user_id: basket.user_id,
          videoFrameIds: basket.frames,
          videoIds: basket.videos,
          classIds: basket.classes
        };
      }))});
  }

  getBasketsEx(filters: any[], sorts: any[], limit: number, offset: number) : Observable<Basket[]> {
    let sort_request: string = "";
    for (let i = 0; i < sorts.length; i++)
    {
      let sort = sorts[i];
      sort_request += ((i == 0) ? '' : ',') + sort.name + ':' + sort.order.toString();
    }

    let params = new HttpParams();
    if (sort_request !== "") {
      params = params.append("sort", sort_request);
    }

    if (limit > 0) {
      params = params.append("limit", limit.toString());
    }    
    if (offset > 0) {
      params = params.append("offset", offset.toString());
    }
    for (let i = 0; i < filters.length; i++)
    {
      let filter = filters[i];
      params = params.append(filter.name, filter.value);
    }    

    return this.httpAuth
      .get<any[]>(`${this.serverUrl}/api/baskets/`, {params : params})
      .map(data => data.map(basket => {

        let basketType = BasketType.VideoFrames;
        if (basket.videos && basket.videos.length > 0) {
          basketType = BasketType.Videos;
        }
        else if (basket.classes && basket.classes.length > 0) {
          basketType = BasketType.Classes;
        }

        return {
          id: basket.id,
          name: basket.name,
          type: basketType,
          user_id: basket.user_id,
          videoFrameIds: basket.frames,
          videoIds: basket.videos,
          classIds: basket.classes
        };
      }));
  }

  getFrames(basket_id : number) : Observable<VideoFrame[]> {
    // @ts-ignore
    let params = new HttpParams();
    params = params.append("basket", basket_id.toString());
    return this.httpAuth.get<VideoFrame[]>(`${this.serverUrl}/api/basket/frames`, {params : params});
  }

  getBasket(basket_id : number) :Observable<Basket> {
    let params = new HttpParams();
    return this.httpAuth.get<Basket>(`${this.serverUrl}/api/baskets/${basket_id}`, {params : params});
  }

  getVideos(basket_id : number) : Observable<UploadedFile[]> {
    // @ts-ignore
    let params = new HttpParams();
    params = params.append("basket", basket_id.toString());
    return this.httpAuth.get<UploadedFile[]>(`${this.serverUrl}/api/basket/videos`, {params : params});
  }

  getObjectClasses(basket : number) : Observable<ObjectOntologyClass[]> {
    // @ts-ignore
    let params = new HttpParams();
    params = params.append("basket", basket.toString());
    return this.httpAuth.get<ObjectOntologyClass[]>(`${this.serverUrl}/api/basket/object_classes`, {params : params});
  }

  createBasket(basket: Basket) : Observable<Basket> {
    if (!basket.name || basket.name  == '' || basket.videoFrameIds.length === 0) {
      console.log('basket.service: createBasket: empty basket name', basket);
      return of(null);
    }

    return Observable
    .fromPromise(Auth.currentAuthenticatedUser())
    .switchMap((currentUser) => {
      const newBasket = {
        name: basket.name,
        user_id: currentUser.username,
        type: BasketType.VideoFrames,
        videoFrameIds: basket.videoFrameIds
      }
      console.log('new basket:', newBasket);

      return this.httpAuth.post<Basket>(`${this.serverUrl}/api/baskets/`, newBasket, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
    });
  }

  updateBasket(basket: Basket) : Observable<Basket> {
    return this.httpAuth.put<Basket>(`${this.serverUrl}/api/baskets/${basket.id}`, basket, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  async deleteBasket(basket: Basket) : Promise<void> {
    console.log("delete basket ", basket);
    await this.httpAuth.delete(`${this.serverUrl}/api/baskets/${basket.id}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).toPromise();
  }

}
