import { Action } from '@ngrx/store';
import { FilterPair } from 'src/app/models/filter_pair';
import { IWithId } from 'src/app/models/i_with_id';

export enum ECollectionActions {
	Load = '[Collection] Load',
	LoadPage = '[Collection] LoadPage',
	LoadSuccess = '[Collection] Load Success',
	Create = '[Collection] Create',
	CreateSuccess = '[Collection] Create Success',
	Update = '[Collection] Update',
	UpdateSuccess = '[Collection] Update success',
	Delete = '[Collection] Delete',
	DeleteSuccess = '[Collection] Delete success'
}

export enum ECollectionItemType {
	Basket     = 'Basket',
	Video      = 'Video',
	VideoFrame = 'VideoFrame',
	Ontology   = 'Ontology'
}

export class Load<T extends IWithId> implements Action {
	public readonly type = ECollectionActions.Load;
	constructor ( public itemType: ECollectionItemType ) {}
}

export class LoadPage<T extends IWithId> implements Action {
	public readonly type = ECollectionActions.LoadPage;
	constructor (
		public itemType: ECollectionItemType,
		public sort: string,
		public sortOrder: number,
		public pageNum: number,
		public limit: number,
		public offset: number,
		public search: string,
		public filters: FilterPair[]
	) {}
}

export class LoadSuccess<T extends IWithId> implements Action {
	public readonly type = ECollectionActions.LoadSuccess;
	constructor ( public items: T[], public itemType: ECollectionItemType ) {}
}

export class Create<T extends IWithId> implements Action {
	public readonly type = ECollectionActions.Create;
	constructor ( public item: T, public itemType: ECollectionItemType ) {}
}

export class CreateSuccess<T extends IWithId> implements Action {
	public readonly type = ECollectionActions.CreateSuccess;
	constructor ( public item: T, public itemType: ECollectionItemType ) {}
}

export class Update<T extends IWithId> implements Action {
	public readonly type = ECollectionActions.Update;
	constructor ( public item: T, public itemType: ECollectionItemType ) {}
}

export class UpdateSuccess<T extends IWithId> implements Action {
	public readonly type = ECollectionActions.UpdateSuccess;
	constructor ( public item: T, public itemType: ECollectionItemType ) {}
}

export class Delete<T extends IWithId> implements Action {
	public readonly type = ECollectionActions.Delete;
	constructor ( public item: T, public itemType: ECollectionItemType ) {}
}

export class DeleteSuccess<T extends IWithId> implements Action {
	public readonly type = ECollectionActions.DeleteSuccess;
	constructor ( public id: number, public itemType: ECollectionItemType ) {}
}

export type CollectionActions<T extends IWithId> =
	Load<T>
	| LoadSuccess<T>
	| Create<T>
	| CreateSuccess<T>
	| Update<T>
	| UpdateSuccess<T>
	| Delete<T>
	| DeleteSuccess<T>;