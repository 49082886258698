import { initialBasketComponentState, BasketComponentState } from '../state/basket-component.state'
import { BasketComponentActions, EBasketComponentActions } from '../actions/basket-component.actions'
import * as _ from 'lodash'

export function basketComponentReducers( 
	state = initialBasketComponentState,
	action: BasketComponentActions
) : BasketComponentState {
	switch (action.type) {
    case EBasketComponentActions.SelectFrameBasket:
      return {
        ... state,
        frameBasketIds: _.uniq([...state.frameBasketIds, action.basketId])
			}
		case EBasketComponentActions.DeSelectFrameBasket:
			return {
				... state,
				frameBasketIds: state.frameBasketIds
					.filter((basketId) => basketId !== action.basketId)
			}
		case EBasketComponentActions.ChangeState:
			return {
				... state,
				state: action.state
			}
		case EBasketComponentActions.SetBasketModified:
			const update = {}
			update[action.basket.id] = action.basket;
			return {
				... state,
				modifiedBaskets: {
					...state.modifiedBaskets,
					...update
				}
			}
		case EBasketComponentActions.ResetModifiedBaskets:
			return {
				... state,
				modifiedBaskets: []
			}
		default:
			return state;
	}
}