import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContentsItem } from 'src/app/models/contents_item';

const DEFAULT_ITEMS_COUNT = 10;

@Component({
  selector: 'contents-item-line',
  templateUrl: './contents-item-line.component.html',
  styleUrls: ['./contents-item-line.component.scss']
})
export class ContentsItemLineComponent implements OnInit {
  @Input("items") items: ContentsItem[];
  @Input("editable") editable: boolean = false;
  @Output("onDelete") onDelete = new EventEmitter<ContentsItem>();

  showAllItems: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  getItemsToShow() {
    if (this.showAllItems) {
      return this.items;
    }
    else {
      return this.items.slice(0, DEFAULT_ITEMS_COUNT); 
    }
  }

  onExpandClick() {
    this.showAllItems = true;
  }

  needShowExpandButton() {
    return this.items.length > DEFAULT_ITEMS_COUNT && !this.showAllItems;
  }

  removeItem(item: ContentsItem) {
    this.onDelete.emit(item);
  }

}
